import React from "react"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"

import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"

import ProjectCard from "@components/index/Projects/ProjectCard/ProjectCard"

import * as s from "./Projects.module.scss"

const IndexProjects = ({ title }) => {
  const {
    allProjectsJson: { nodes: projects },
  } = useStaticQuery(graphql`
    query getProjects {
      allProjectsJson {
        nodes {
          title
          description
          link_production
          link_github
          tags
          images {
            alt
            src {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section className={s.container} id="projects">
      <h1 className={s.title}>{title}</h1>
      <Container className={s.projectsContainer}>
        <Row>
          {projects.map(project => (
            <Col key={project.title} xs={12} md={4}>
              <ProjectCard project={project} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

IndexProjects.propTypes = {
  title: PropTypes.string.isRequired,
}

export default IndexProjects
