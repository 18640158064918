import React from "react"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"

import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"

import SkillCard from "@components/index/Skills/SkillCard/SkillCard"

import * as s from "./Skills.module.scss"

const IndexSkills = ({ title }) => {
  const {
    allSkillsJson: { nodes: skills },
  } = useStaticQuery(graphql`
    query getSkills {
      allSkillsJson {
        nodes {
          images {
            src {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            alt
          }
          title
          subtitle
        }
      }
    }
  `)

  return (
    <section className={s.container} id="skills">
      <h1 className={s.title}>{title}</h1>
      <Container className={s.skillsContainer}>
        <Row>
          {skills.map(skill => (
            <Col key={skill.title} xs={12} md={4}>
              <SkillCard skill={skill} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

IndexSkills.propTypes = {
  title: PropTypes.string.isRequired,
}

export default IndexSkills
