import React from "react"
import PropTypes from "prop-types"

import { GatsbyImage } from "gatsby-plugin-image"

import * as s from "./SkillCard.module.scss"

const SkillCard = ({ skill: { title, subtitle, images } }) => {
  const firstImage = images[0] || {}

  return (
    <div className={s.container}>
      <div className={s.images}>
        <div className={s.imgWrapper}>
          {firstImage.src && firstImage.src.childImageSharp && (
            <GatsbyImage
              image={firstImage.src.childImageSharp.gatsbyImageData}
              alt={firstImage.alt}
            />
          )}
        </div>
      </div>
      <div className={s.text}>
        <h3 className={s.title}>{title}</h3>
        <h4 className={s.subtitle}>{subtitle}</h4>
      </div>
    </div>
  )
}

SkillCard.propTypes = {
  skill: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        alt: PropTypes.string.isRequired,
        src: PropTypes.object.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
}

export default SkillCard
