import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-scroll"

const NavigationItem = ({
  to,
  children,
  scrollDuration,
  smooth,
  spy,
  isDynamic,
  activeClass
}) => (
  <Link
    to={to}
    duration={scrollDuration}
    smooth={smooth}
    isDynamic={isDynamic}
    spy={spy}
    href={`#${to}`}
    activeClass={activeClass}
  >
    {children}
  </Link>
)

NavigationItem.defaultProps = {
  scrollDuration: 300,
  smooth: true,
  spy: true,
  isDynamic: true,
}

NavigationItem.propTypes = {
  to: PropTypes.string.isRequired,
  activeClass: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  scrollDuration: PropTypes.number,
  smooth: PropTypes.bool,
  spy: PropTypes.bool,
  isDynamic: PropTypes.bool,
}

export default NavigationItem
