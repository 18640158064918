import React, { useMemo, useCallback } from "react"

import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import { Waypoint } from "react-waypoint"

import useAppContext from "@contexts/AppContext"

import MainLayout from "@components/MainLayout"
import Seo from "@components/Seo"
import SideNav from "@components/index/SideNav/SideNav"
import Hero from "@components/index/Hero/Hero"
import About from "@components/index/About/About"
import Skills from "@components/index/Skills/Skills"
import Projects from "@components/index/Projects/Projects"

import SeparatorShape from "@images/separator-shape.svg"

const Separator = () => (
  <div className="text-center my-5 px-3">
    <SeparatorShape style={{ maxWidth: "100%" }} />
  </div>
)

const IndexPage = () => {
  const intl = useIntl()

  const messages = useMemo(
    () => ({
      seo: {
        title: intl.formatMessage({ id: "index.seo.title" }),
        description: intl.formatMessage({ id: "index.seo.description" }),
      },
      hero: {
        title: (
          <FormattedMessage
            id="index.hero.title"
            values={{
              br: <br />,
            }}
          />
        ),
        subtitle: (
          <FormattedMessage
            id="index.hero.subtitle"
            values={{
              br: <br />,
            }}
          />
        ),
      },
      about: {
        title: intl.formatMessage({ id: "index.about.title" }),
        text: (
          <FormattedMessage
            id="index.about.text"
            values={{
              br: <br />,
            }}
          />
        ),
      },
      skills: {
        title: intl.formatMessage({ id: "index.skills.title" }),
      },
      projects: {
        title: intl.formatMessage({ id: "index.projects.title" }),
      },
    }),
    [intl]
  )

  const { setNavSticky } = useAppContext()

  const handlePositionChanged = useCallback(
    ({ currentPosition }) => {
      if (currentPosition === "inside") {
        setNavSticky(false)
      } else if (currentPosition === "above") {
        setNavSticky(true)
      }
    },
    [setNavSticky]
  )

  return (
    <MainLayout>
      <Seo
        lang={intl.locale}
        title={messages.seo.title}
        description={messages.seo.description}
      />
      <div className="d-none IN-DEVELOPMENT">
        <SideNav />
      </div>
      <Hero title={messages.hero.title} subtitle={messages.hero.subtitle} />
      <Waypoint onPositionChange={handlePositionChanged} />
      <About title={messages.about.title} text={messages.about.text} />
      <Separator />
      <Skills title={messages.skills.title} />
      <div className="d-none IN-DEVELOPMENT">
        <Separator />
        <Projects title={messages.projects.title} />
      </div>
    </MainLayout>
  )
}

IndexPage.propTypes = {}

export default IndexPage
