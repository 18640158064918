import React from "react"
import PropTypes from "prop-types"

import { StaticImage } from "gatsby-plugin-image"
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import { Link } from "react-scroll"

import Arrow from "@images/double-arrow.svg"
import Wave from "../Wave"

import * as s from "./Hero.module.scss"

const IndexHero = ({ title, subtitle }) => (
  <section className={s.container} id="hero">
    <Container>
      <Row noGutters>
        <Col xs={12} md={7}>
          <div className={s.verticalCenter}>
            <h1 className={s.title}>{title}</h1>
            <h2 className={s.subtitle}>{subtitle}</h2>
          </div>
        </Col>
        <Col xs={12} md={5}>
          <div className={s.verticalCenter}>
            <StaticImage
              src="../../../images/avatar.png"
              alt="Avatar"
              placeholder="blurred"
              layout="fullWidth"
            />
          </div>
        </Col>
      </Row>
    </Container>
    <Link
      className={s.arrowWrapper}
      to="about"
      offset={100}
      duration={300}
      smooth
      isDynamic
    >
      <Arrow />
    </Link>
    <div className={s.waveWrapper}>
      <Wave />
    </div>
  </section>
)

IndexHero.propTypes = {
  title: PropTypes.object.isRequired,
  subtitle: PropTypes.object.isRequired,
}

export default IndexHero
