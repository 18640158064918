import React from "react"
import PropTypes from "prop-types"

import AboutShape from "@images/about-shape.svg"

import * as s from "./About.module.scss"

const IndexAbout = ({ title, text }) => (
  <section className={s.container} id="about">
    <div className={s.verticalCenter}>
      <h1 className={s.title}>{title}</h1>
      <p className={s.text}>{text}</p>
    </div>
    <div className={s.shapeWrapper}>
      <AboutShape />
    </div>
  </section>
)

IndexAbout.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
}

export default IndexAbout
