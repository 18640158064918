import React, { useEffect } from "react"
import { scrollSpy } from "react-scroll"

import SideNavItem from "./SideNavItem"
import * as s from "./SideNav.module.scss"

const SideNav = () => {
  useEffect(() => {
    scrollSpy.update()
  }, [])

  return (
    <nav className={s.nav}>
      <SideNavItem to="about" activeClass={s.active}>About</SideNavItem>
      <SideNavItem to="skills" activeClass={s.active}>Skills</SideNavItem>
    </nav>
  )
}

SideNav.defaultProps = {}

SideNav.propTypes = {}

export default SideNav
