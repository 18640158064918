import React from "react"
import PropTypes from "prop-types"

import * as s from "./ProjectCard.module.scss"

const ProjectCard = ({ project: { title } }) => (
  <div className={s.container}>{title}</div>
)

ProjectCard.propTypes = {
  project: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default ProjectCard
